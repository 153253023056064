import { FC, Fragment } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { YesNoFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';

const YesNoFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const yesNoFilters = filter as YesNoFilterValue | undefined;
  const { t } = useTranslation(['activity-type', 'table-view']);
  const valueMap = {
    false: t('table-view:filters.no-data'),
    yes: t('yes-no.yes'),
    no: t('yes-no.no'),
    na: t('yes-no.not-applicable'),
  } as const;

  return (
    <div className="flex flex-wrap items-center gap-2">
      {yesNoFilters?.map((x, i) => (
        <Fragment key={`${x}-${i}`}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={valueMap[x as keyof typeof valueMap]} />
        </Fragment>
      ))}
    </div>
  );
};

export default YesNoFilterOverview;
